<template>
  <div class="page">
    <el-card class="container">
      <el-card class="tableContainer">
        <div class="header" slot="header">
          <div class="title">服务标签列表</div>
        </div>
        <div class="tagContainer">
          <div class="tagList">
            <el-tag size="medium" v-for="i in list" :key="i.id" class="tagItem" @click="handleEdit(i)">{{
              i.serviceContent
            }}</el-tag>
          </div>
          <div class="action">
            <el-button class="button-new-tag" size="medium" type="success" @click="handleAdd">添加新服务标签</el-button>
          </div>
        </div>
      </el-card>

      <el-dialog :visible.sync="editData.isShow" :title="editData.title">
        <el-form class="editContainer">
          <el-form-item label="标签名称">
            <el-input
              maxlength="6"
              show-word-limit
              v-model.trim="editData.serviceContent"
              placeholder="请输入新的标签名称"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="editContainerFooter">
          <el-button @click="submitDelete" plain type="danger" icon="el-icon-delete" v-show="editData.id !== -1"
            >删除</el-button
          >
          <div>
            <el-button @click="editData.isShow = false">取 消</el-button>
            <el-button @click="submitEdit" type="primary">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    list: [],
    newTagValue: '',
    editData: {
      isShow: false,
      id: '',
      edit: false,
      title: '',
      serviceContent: ''
    }
  }),
  methods: {
    getList() {
      return this.$http.get('/boom-center-product-service/sysAdmin/productService/').then(res => {
        this.list = res
      })
    },
    handleAdd() {
      this.editData = {
        isShow: true,
        title: '新增标签',
        id: -1,
        edit: false,
        serviceContent: ''
      }
      this.editData.edit = false
    },
    handleEdit(val) {
      this.editData = {
        isShow: true,
        id: val.id,
        edit: true,
        serviceContent: val.serviceContent,
        title: '编辑标签'
      }
    },
    submitEdit() {
      if (!this.editData.serviceContent) {
        return
      }
      if (this.editData.edit) {
        return this.$http
          .put(`/boom-center-product-service/sysAdmin/productService/${this.editData.id}`, {
            serviceContent: this.editData.serviceContent
          })
          .then(res => {
            this.editData.isShow = false
            this.$message.success('修改成功')
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
      } else {
        return this.$http
          .post('/boom-center-product-service/sysAdmin/productService/', {
            serviceContent: this.editData.serviceContent
          })
          .then(res => {
            this.editData.isShow = false
            this.$message.success('添加成功')
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
      }
    },
    submitDelete() {
      if (!this.editData.serviceContent) {
        return
      }
      return this.$http
        .delete(`/boom-center-product-service/sysAdmin/productService/${this.editData.id}`)
        .then(res => {
          this.editData.isShow = false
          this.$message.success('删除成功')
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  },
  created() {},
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.container {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.page {
  width: 100%;
}
.tagContainer {
  .tagItem {
    margin: 10px;
  }
  .action {
    margin-left: 10px;
    .addInput {
      width: 200px;
    }
  }
}
.editContainer {
  .label {
    margin-bottom: 10px;
  }
  .el-input {
    width: 250px;
  }
}
.editContainerFooter {
  display: flex;
  justify-content: space-between;
}
</style>
